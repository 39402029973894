import { useEffect, useMemo, useCallback, useState } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core';
import { Controller, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { values, without } from 'lodash';

import Button from '@shared/components/button';

import useActions from '@shared/hooks/useActions';
import { actions } from '@store/users';
import { isAdmin, UserRole } from '@shared/models';
import { defaultUser } from '@shared/constants';
import CompanySelector from './CompanySelector';
import { composeInitialProps } from 'react-i18next';
import { Alert } from '@material-ui/lab';


const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: '2rem',
  },
  content: {
    width: '400px',
    alignItems: 'center',
  },
  formControl: {
    margin: theme.spacing(1, 0),
  },
  actions: {
    padding: theme.spacing(2),
    justifyContent: 'center',
  },
  helperText: {
    margin: theme.spacing(0.375, 1.75, 0),
  },
  alert: {
    alignItems: 'center',
    width: '350px',
    fontSize: '10px',
    margin: 'auto',
  },
}));

export const ActionType = {
  CREATE: 'Create',
  UPDATE: 'Update',
};

const UserModal = ({ open, onClose, type, data }) => {
  const curUser = useSelector((state) => state.app.user);
  const companies = useSelector((state) => state.companies.companies);
  const users2 = useSelector((state) => state.users.users);

  const [alertMessage, setAlertMessage] = useState('');

  const { createUser, updateUser } = useActions(actions);

  const getDefaultValues = useCallback(() => ({
    ...defaultUser,
    ...data,
    company: companies.filter((company) => company.id === data?.company).shift() ?? companies[0],
  }), [data, companies]);

  const form = useForm({
    defaultValues: getDefaultValues(),
  });
  const { control, handleSubmit, reset } = form;

  const classes = useStyles();

  const roles = useMemo(() => {
    const allRoles = values(UserRole);
    return isAdmin(curUser) ? allRoles : without(allRoles, UserRole.ADMIN);
  }, [curUser]);

  const handleAlertClose = () => setAlertMessage('');

  const onSubmit = (data) => {
    setAlertMessage('');
    const isCurUserAdmin = isAdmin(curUser);
    if (isCurUserAdmin && data.role === UserRole.COMPANY_ADMIN) {
      const companyIdFromForm = data.company.id;
      const usersFromCompany = users2.filter(user => user.company === companyIdFromForm);
      const hasCompanyAdmin = usersFromCompany.some(user => user.role === UserRole.COMPANY_ADMIN);
      if (hasCompanyAdmin) {
        setAlertMessage('It’s not allowed to have 2 company admins.');
        return;
      }
    }
    if (!isCurUserAdmin && data.role === UserRole.COMPANY_ADMIN) {
      setAlertMessage('It’s not allowed to have 2 company admins.');
      return;
    }
    onClose();
    const { company, ...rest } = data;
    const userData = { ...rest, company: company?.id };

    if (type === ActionType.CREATE) createUser(userData);
    if (type === ActionType.UPDATE) updateUser({ id: data.id, user: userData });
  };

  useEffect(() => {
    if (open) {
      setAlertMessage('');
      reset(getDefaultValues());
    }
  }, [open, reset, getDefaultValues]);

  return (
    <Dialog open={ open } onClose={ onClose }>
      <DialogTitle className={ classes.title } disableTypography>{type} User</DialogTitle>
      <DialogContent className={ classes.content }>
        <form noValidate onSubmit={ handleSubmit(onSubmit) }>
          {isAdmin(curUser) && (
            <FormControl fullWidth className={ classes.formControl }>
              <Controller
                name="company"
                control={ control }
                rules={ { required: 'Required' } }
                render={ ({ field: { value, onChange }, fieldState: { error } }) => (
                  <CompanySelector
                    value={ value }
                    onChange={ onChange }
                    error={ error }
                  />
                ) }
              />
            </FormControl>
          )}
          <FormControl fullWidth className={ classes.formControl }>
            <Controller
              name="email"
              render={ ({ field, fieldState: { error } }) => (
                <TextField
                  { ...field }
                  id="email"
                  helperText={ error?.message ?? null }
                  variant="outlined"
                  label="Email"
                  required
                  error={ !!error }
                />
              ) }
              control={ control }
              rules={ { required: 'Required' } }
            />
          </FormControl>
          <FormControl required fullWidth className={ classes.formControl }>
            <Controller
              name="role"
              control={ control }
              rules={ { required: 'Required' } }
              render={ ({ field, fieldState: { error } }) => (
                <>
                  <InputLabel id="role-label" variant="outlined" error={ !!error }>Role</InputLabel>
                  <Select
                    { ...field }
                    id="role"
                    labelId="role-label"
                    variant="outlined"
                    label="Role"
                    error={ !!error }
                  >
                    {roles.map((r) => <MenuItem key={ r } value={ r }>{ r }</MenuItem>)}
                  </Select>
                  { error?.message && (
                    <FormHelperText
                      className={ classes.helperText }
                      error
                    >
                      { error?.message }
                    </FormHelperText>
                  )}
                </>
              ) }
            />
          </FormControl>
        </form>
      </DialogContent>
      {alertMessage && (
        <Alert onClose={ handleAlertClose } severity="error" className={ classes.alert }>
          { alertMessage }
        </Alert>
      )}
      <DialogActions className={ classes.actions }>
        <Button color="secondary" variant="outlined" onClick={ () => { onClose(); setAlertMessage(''); } }>Cancel</Button>
        <Button onClick={ handleSubmit(onSubmit) }>Save</Button>
      </DialogActions>
    </Dialog>
  );
};

export default UserModal;
