import { useState, useMemo, useRef } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import Box from '@material-ui/core/Box';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import classNames from 'classnames';
import Tooltip from '@material-ui/core/Tooltip';
// eslint-disable-next-line import/no-extraneous-dependencies
import { DataGrid } from '@mui/x-data-grid';
import { makeStyles } from '@material-ui/core/styles';
import SquareWrapper from '@shared/components/page-parts/SquareWrapper';
import ImageFullSize from '@shared/components/image-full-size';
import { ModelViewer } from '@shared/views/form-dialog';
import { LineBasicMaterial } from 'three';


const useStyles = makeStyles(() => ({
  table: {
    display: 'flex',
    height: '100%',
    width: '100%',
    '& .MuiDataGrid-window': {
      overflowX: 'hidden',
    },
  },
  tooltipText: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: '175px',
    fontSize: '14px',
    fontWeight: '400',
    lineHeight: '21px',
    letterSpacing: '0.01em',
  },
  tooltipName: {
    color: '#102035',
  },
  customTooltip: {
    fontSize: '1.3em',
  },
}));
const FormFieldset = ({
  data,
}) => {
  const classes = useStyles();
  const {
    control,
  } = useFormContext();

  const {
    field: { onChange: onChangeMaterials, value: materialsValue },
  } = useController({
    name: 'materials',
    control,
  });
  const isFirstSelection = useRef([]);
  const [tab, setTab] = useState(0);
  const rows = useSelector((state) => state.materials.materials.items);

  const columns = [
    {
      field: 'name',
      headerName: 'Name',
      width: 150,
      renderCell: (params) => (
        params.value.length > 18 ? (
        <Tooltip title={ params.value } placement="top" classes={ { tooltip: classes.customTooltip } }>
          <span className={ classNames(classes.tooltipText, classes.tooltipName) }>
            { params.value }
          </span>
        </Tooltip>
        ) : null
      ),
    },
    {
      field: 'url',
      headerName: 'Preview',
      width: 100,
      sortable: false,
      renderCell: (params) => {
        const imageUrl = params.value ? `${params.value}?size=128` : '../../assets/images/no_image.png';
        return <img src={ imageUrl } alt={ data.value } style={ { width: '100%' } } />;
      },
    },
  ];

  const handleTabChange = (event, newValue) => {
    setTab(newValue);
  };

  const groups = useMemo(() => ['All Groups', ...new Set(data.model?.materials?.map((m) => m.group?.id) || ['All Groups'])], [data.model]);

  const materials = useMemo(() => {
    const wireframeMaterial = new LineBasicMaterial({ name: 'ZolakWireframe', color: 0x00FF66 });
    wireframeMaterial.wireframe = true;
    return data.model.materials.map((m) => {
      if (tab === 0 || m.group?.id === groups[tab]) {
        return {
          ...m,
          material: wireframeMaterial,
        };
      }
      return m;
    });
  }, [data.model, groups, tab]);

  return (
    <Box style={ { display: 'flex', flexDirection: 'column', height: '100%' } }>
      <Tabs
        orientation="horizontal"
        variant="scrollable"
        value={ tab }
        onChange={ handleTabChange }
        aria-label="Material tabs"
        style={ { borderRight: 1, borderColor: 'divider', flex: '0 0 auto' } }
      >
        {
            groups.map((groupId) => {
              const groupName = data.model?.materials?.find((m) => m.group?.id === groupId)?.group?.name || groupId;

              return (
                <Tab key={ groupId } label={ groupName || 'Non-configurable Part' } />
              );
            })
          }
      </Tabs>
      <Box style={ { display: 'flex', height: '100%', width: '100%' } }>
        <div style={ { flex: '1 1 auto' } }>
          {
              groups.map((groupId, index) => (
                <div
                  key={ groupId }
                  role="tabpanel"
                  style={ { padding: '8px 0', overflow: 'auto', flex: '1 1 auto', height: '100%', width: '100%' } }
                  hidden={ tab !== index }
                  id={ `vertical-tabpanel-${index + 1}` }
                  aria-labelledby={ `vertical-tab-${index + 1}` }
                >
                  <DataGrid
                    style={ { fontSize: '14px' } }
                    className={ classes.table }
                    rows={ rows }
                    columns={ columns }
                    initialState={ {
                      pagination: {
                        paginationModel: { page: 0, pageSize: 5 },
                      },
                    } }
                    pageSizeOptions={ [5, 10] }
                    checkboxSelection
                    selectionModel={ (materialsValue || []).find((m) => m.group === groupId)?.materials }
                    onSelectionModelChange={ (newSelectionModel) => {
                      const found = (materialsValue || []).find((m) => m.group === groupId);
                      if (found) {
                        if (isFirstSelection.current.indexOf(groupId) === -1 && newSelectionModel.length === 0) {
                          isFirstSelection.current.push(groupId);
                          return;
                        }
                        found.materials = newSelectionModel;
                        onChangeMaterials([...materialsValue]);
                      } else {
                        isFirstSelection.current.push(groupId);
                        onChangeMaterials([...(materialsValue || []), {
                          group: groupId,
                          materials: newSelectionModel,
                        }]);
                      }
                    } }
                  />
                </div>
              ))
            }
        </div>
        <div style={ { flex: '0 0 400px' } }>
          <SquareWrapper>
            <ImageFullSize>
              <ModelViewer
                value={ data.model.model }
                materials={ materials }
              />
            </ImageFullSize>
          </SquareWrapper>
        </div>
      </Box>
    </Box>
  );
};

export default FormFieldset;
