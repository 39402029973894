import { useEffect, useMemo } from 'react';
import { suspend, preload, clear } from 'suspend-react';
import {
  TextureLoader,
  MeshPhysicalMaterial,
  RepeatWrapping,
  DoubleSide,
  SRGBColorSpace,
  Color,
  Box3,
  Vector3,
  NoColorSpace,
} from 'three';
import { getGeometryArea } from '@zolak/zolak-viewer';
import { DefaultMaterialName } from '@shared/constants';

const is = {
  obj: (a) => a === Object(a) && !is.arr(a) && typeof a !== 'function',
  fun: (a) => typeof a === 'function',
  str: (a) => typeof a === 'string',
  num: (a) => typeof a === 'number',
  boo: (a) => typeof a === 'boolean',
  und: (a) => a === undefined,
  arr: (a) => Array.isArray(a),
  equ(a, b, { arrays = 'shallow', objects = 'reference', strict = true } = {}) {
    // Wrong type or one of the two undefined, doesn't match
    if (typeof a !== typeof b || !!a !== !!b) return false;
    // Atomic, just compare a against b
    if (is.str(a) || is.num(a)) return a === b;
    const isObj = is.obj(a);
    if (isObj && objects === 'reference') return a === b;
    const isArr = is.arr(a);
    if (isArr && arrays === 'reference') return a === b;
    // Array or Object, shallow compare first to see if it's a match
    if ((isArr || isObj) && a === b) return true;
    // Last resort, go through keys
    let i;
    // Check if a has all the keys of b
    for (i in a) if (!(i in b)) return false;
    // Check if values between keys match
    if (isObj && arrays === 'shallow' && objects === 'shallow') {
      for (i in strict ? b : a) if (!is.equ(a[i], b[i], { strict, objects: 'reference' })) return false;
    } else {
      for (i in strict ? b : a) if (a[i] !== b[i]) return false;
    }
    // If i is undefined
    if (is.und(i)) {
      // If both arrays are empty we consider them equal
      if (isArr && a.length === 0 && b.length === 0) return true;
      // If both objects are empty we consider them equal
      if (isObj && Object.keys(a).length === 0 && Object.keys(b).length === 0) return true;
      // Otherwise match them by value
      if (a !== b) return false;
    }
    return true;
  },
};

function loadingFn(
  object,
  textureSize,
  materials,
  onProgress,
) {
  return () => {
    const loader = new TextureLoader();

    const meshes = [];
    object.traverse((element) => {
      if (element.isMesh) {
        meshes.push(element);
      }
    });

    const promises = [].concat(...materials.map((m, index) => {
      const { material } = m;
      const mesh = meshes[index];
      const box = new Box3();

      if (!material?.isMaterial && !material?.configuration && !material?.textures && object.userData?.originalMaterials[mesh.uuid]) {
        return Promise.resolve(object.userData.originalMaterials[mesh.uuid]);
      }

      box.setFromObject(mesh);
      const newMaterial = new MeshPhysicalMaterial({ name: DefaultMaterialName, side: DoubleSide });
      Object.keys(material?.configuration || {}).forEach((key) => {
        if (['color', 'sheenColor'].indexOf(key) > -1) {
          newMaterial[key] = new Color(material.configuration[key]);
          return;
        }
        newMaterial[key] = material.configuration[key];
      });
      const availableTextures = Object.keys(material?.textures || {})
        .filter((key) => material.textures[key] && material.textures[key].url)
        .reduce((acc, key) => ({ ...acc, [key]: material.textures[key].url }), {});

      return Promise.all(
        Object.keys(availableTextures).map(
          (key) => new Promise((resolve, reject) => loader.load(
            // TODO: Use texture size quality here
            textureSize ? `${availableTextures[key]}?size=${textureSize}` : availableTextures[key],
            (texture) => {
              newMaterial[key] = texture;
              if (key === 'map' || key === 'sheenColorMap') {
                newMaterial[key].colorSpace = SRGBColorSpace;
              } else {
                newMaterial[key].colorSpace = NoColorSpace;
              }
              newMaterial[key].wrapS = RepeatWrapping;
              newMaterial[key].wrapT = RepeatWrapping;
              newMaterial[key].flipY = false;
              newMaterial[key].anisotropy = 8;
              newMaterial[key].needsUpdate = true;
              resolve(texture);
            },
            onProgress,
            (error) => reject(new Error(`Could not load ${availableTextures[key]}: ${error.message})`)),
          )),
        ),
      ).then((textures) => {
        if (textures.length) {
          return newMaterial;
        }
        if (material && material.isMaterial) {
          if (material.map) {
            material.map.anisotropy = 8;
          }
          if (material.sheenColorMap) {
            material.sheenColorMap.anisotropy = 8;
          }
          return material;
        }
        if (mesh.material && mesh.material.map) {
          mesh.material.map.anisotropy = 8;
        }
        if (mesh.material && mesh.material.sheenColorMap) {
          mesh.material.sheenColorMap.anisotropy = 8;
        }
        return mesh.material;
      });
    }));

    return Promise.all(promises);
  };
}

/**
   * Synchronously loads and caches materials with a three loader.
   *
   * Note: this hook's caller must be wrapped with `React.Suspense`
   */
function useMaterials(
  object,
  scale,
  materials,
  textureSize,
  objectUrl,
  onProgress,
) {
  // Use suspense to load async assets
  const urls = useMemo(() => {
    const result = [objectUrl];
    materials.forEach(({ material }) => {
      const availableTextures = Object.keys(material?.textures || {})
        .filter((key) => material.textures[key] && material.textures[key].url)
        .reduce((acc, key) => ({ ...acc, [key]: material.textures[key].url }), {});
      if (!Object.keys(availableTextures).length) {
        result.push(`${textureSize}`);
      } else {
        Object.keys(availableTextures).forEach((key) => {
          result.push((textureSize ? `${availableTextures[key]}?size=${textureSize}` : availableTextures[key]));
        });
      }
    });
    return result;
  }, [materials, textureSize, objectUrl]);

  const results = suspend(loadingFn(object, textureSize, materials, onProgress), [...urls], { equal: is.equ });

  useEffect(() => {
    const meshes = [];
    object.traverse((element) => {
      if (element.isMesh) {
        meshes.push(element);
      }
    });
    results.forEach((threeMaterial, index) => {
      const mesh = meshes[index];
      const groupedMaterial = materials[index];
      if (!mesh) {
        return;
      }

      if (groupedMaterial) {
        const { material } = groupedMaterial;
        const raportX = material?.raportX || 0;
        const raportY = material?.raportY || 0;
        const uvScaleX = groupedMaterial.uvScaleX || 1;
        const uvScaleY = groupedMaterial.uvScaleY || 1;
        const uvOffsetX = groupedMaterial.uvOffsetX || 0;
        const uvOffsetY = groupedMaterial.uvOffsetY || 0;
        const uvRotation = groupedMaterial.rotation || 0;
        const meshArea = getGeometryArea(mesh.geometry, scale, mesh.scale?.toArray());
        const materialArea = raportX * raportY;
        const repeat = meshArea / materialArea;
        const repeatPercentX = (repeat - (raportX * repeat) / (raportX + raportY)) / repeat;
        const repeatPercentY = (repeat - (raportY * repeat) / (raportX + raportY)) / repeat;
        const baseRepeat = Math.sqrt(repeat / repeatPercentX / repeatPercentY);
        const repeatX = baseRepeat * repeatPercentX;
        const repeatY = baseRepeat * repeatPercentY;
        ['map', 'normalMap', 'roughnessMap', 'metalnessMap',
          'displacementMap', 'specularColorMap', 'specularIntensityMap', 'alphaMap',
          'sheenColorMap', 'sheenRoughnessMap', 'clearcoatMap',
          'clearcoatNormalMap', 'clearcoatRoughnessMap', 'bumpMap',
        ].forEach((key) => {
          if (threeMaterial[key]) {
            threeMaterial[key].repeat.set(raportX === 0 ? 1 * uvScaleX : repeatX * uvScaleX, raportY === 0 ? 1 * uvScaleY : repeatY * uvScaleY);
            threeMaterial[key].offset.set(uvOffsetX, uvOffsetY);
            threeMaterial[key].rotation = uvRotation;
          }
        });
      }

      if (!threeMaterial.aoMap) {
        threeMaterial.aoMap = mesh.material.aoMap;
      }

      if (mesh.material.name === DefaultMaterialName) {
        threeMaterial.envMapIntensity = mesh.material.envMapIntensity;
      }

      mesh.material = threeMaterial;
      if (mesh.material.alphaMap) {
        mesh.material.transparent = true;
      }
      mesh.material.needUpdate = true;
    });
  }, [object, results, materials, scale]);

  // Return the object/s
  return results;
}

/**
   * Preloads an material into cache as a side-effect.
   */
useMaterials.preload = (
  object,
  materials,
  textureSize,
  objectUrl,
) => {
  const urls = [objectUrl];
  materials.forEach(({ material }) => {
    const availableTextures = Object.keys(material?.textures || {})
      .filter((key) => material.textures[key] && material.textures[key].url)
      .reduce((acc, key) => ({ ...acc, [key]: material.textures[key].url }), {});
    Object.keys(availableTextures).forEach((key) => {
      urls.push((textureSize ? `${availableTextures[key]}?size=${textureSize}` : availableTextures[key]));
    });
  });
  return preload(loadingFn(object, textureSize, materials), [...urls]);
};

/**
   * Removes a loaded asset from cache.
   */
useMaterials.clear = (Proto, input) => {
  const keys = (Array.isArray(input) ? input : [input]);
  return clear([Proto, ...keys]);
};

export default useMaterials;
