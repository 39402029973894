import { useState, useMemo } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
// eslint-disable-next-line import/no-extraneous-dependencies
import { SliderPicker } from 'react-color';
import { useSelector } from 'react-redux';

import {
  TextField,
  Fields,
  Field,
  GroupField,
} from '@shared/views/form-dialog';
import MaterialModel from '@shared/views/form-dialog/MaterialModel';
import TextureTextField from './TextureTextField';
import { Fieldset, InputLabelProps } from './fieldset';
import RangeSlider from '../../scenes/child-details/LightDetails/RangeSlider';
import AttributesTable from '@shared/components/attributes-table/AttributesTable';
import TranslatedFieldsGroup from '@shared/components/translations/TranslatedGroupFields';
import { selectCurrentCompany } from '@store/app/selectors';
import { AVAILABLE_LANGUAGES } from '@shared/utils/translations';
import { DefaultLightPresetsData } from '@pages/products/constants';
import { selectAttributeDefinitions } from '@store/attribute-definitions/selectors';

const useStyles = makeStyles((theme) => ({
  box: {
    flexGrow: 1,
    display: 'flex',
    height: '100%',
  },
  tabs: {
    borderRight: 1,
    borderColor: 'divider',
    flex: '0 0 auto',
  },
  tab: {
    '& .MuiTab-wrapper': {
      alignItems: 'flex-start',
      color: '#5F6B79',
      fontSize: '1.8rem',
      lineHeight: '27px',
      textTransform: 'initial',
    },

    '&.Mui-selected': {
      backgroundColor: '#E2EAF3',

      '& .MuiTab-wrapper': {
        color: theme.palette.common.darkBlue,
      },
    },
  },
  tabpanel0: {
    padding: '8px 10px',
    overflow: 'auto',
    flex: '1 1 auto',
  },
  tabpanel1: {
    padding: '8px 25px 8px 10px',
    overflow: 'auto',
    flex: '1 1 auto',
  },
  modelWrapper: {
    width: 299,
    height: '100%',
    flex: '0 0 auto',
    padding: '0px 10px',
    display: 'flex',
    flexDirection: 'column',
    gap: '100px',
  },
}));

const FormFieldset = () => {
  const {
    control,
    formState: { errors: fieldsErrors },
  } = useFormContext();
  const classes = useStyles();
  const allEnvironments = useSelector((state) => state.scenes.environments.items);
  const environment = allEnvironments?.find((item) => item.uid === DefaultLightPresetsData[1].uid);

  const attributeDefinitions = useSelector(selectAttributeDefinitions);

  const values = useWatch({ control });
  const company = useSelector(selectCurrentCompany);
  const textureOptions = useSelector((state) => state.materials.textures.items);

  const [selectedLanguage, setSelectedLanguage] = useState(AVAILABLE_LANGUAGES.find((lng) => lng.key === 'en'));
  const useLang = useMemo(() => selectedLanguage && selectedLanguage.key !== 'en', [selectedLanguage]);

  const [tab, setTab] = useState(0);
  const handleTabChange = (event, newValue) => {
    setTab(newValue);
  };

  return (
    <Box className={ classes.box }>
      <Tabs
        orientation="vertical"
        variant="scrollable"
        value={ tab }
        onChange={ handleTabChange }
        aria-label="Material tabs"
        className={ classes.tabs }
        TabIndicatorProps={ { style: { display: 'none' } } }
      >
        <Tab label="Details" className={ classes.tab } />
        <Tab label="Configuration" className={ classes.tab } />
        <Tab label="Attributes" className={ classes.tab } />
      </Tabs>
      <div
        role="tabpanel"
        className={ classes.tabpanel0 }
        hidden={ tab !== 0 }
        id="vertical-tabpanel-0"
        aria-labelledby="vertical-tab-0"
      >
        <Fields>
          <GroupField>
            <Controller
              name="raportX"
              control={ control }
              defaultValue="1"
              rules={ {
                required: 'Required',
              } }
              render={ ({ field }) => (
                <TextField
                  { ...field }
                  helperText={ fieldsErrors.raportX?.message }
                  type="number"
                  label="Raport X"
                  required
                  error={ !!fieldsErrors.raportX }
                />
              ) }
            />
            <Controller
              name="raportY"
              control={ control }
              defaultValue="1"
              rules={ {
                required: 'Required',
              } }
              render={ ({ field }) => (
                <TextField
                  { ...field }
                  helperText={ fieldsErrors.raportY?.message }
                  type="number"
                  label="Raport Y"
                  required
                  error={ !!fieldsErrors.raportY }
                />
              ) }
            />
          </GroupField>
          {
            Object.keys(Fieldset.Textures).map((name) => {
              const texture = Fieldset.Textures[name];
              return (
                <Field key={ name } style={ { position: 'relative' } }>
                  <Controller
                    name={ texture.key }
                    control={ control }
                    defaultValue={ { type: texture.type, name: '', url: '' } }
                    rules={ {
                      required: 'Required',
                    } }
                    render={ ({ field }) => (
                      <TextureTextField
                        { ...field }
                        helperText={ fieldsErrors[texture.key]?.message }
                        label={ texture.label }
                        required={ texture.required }
                        error={ !!fieldsErrors[texture.key] }
                        options={ textureOptions }
                      />
                    ) }
                  />
                </Field>
              );
            })
          }
        </Fields>
      </div>
      <div
        role="tabpanel"
        className={ classes.tabpanel1 }
        hidden={ tab !== 1 }
        id="vertical-tabpanel-1"
        aria-labelledby="vertical-tab-1"
      >
        <Fields>
          {
            Object.keys(Fieldset.Configuration).map((name) => {
              const fieldDef = Fieldset.Configuration[name];
              if (fieldDef.input === 'slider') {
                return (
                  <Field key={ name }>
                    <Controller
                      name={ fieldDef.key }
                      defaultValue={ fieldDef.default }
                      render={ ({ field }) => (
                        <RangeSlider
                          label={ fieldDef.label }
                          min={ fieldDef.min }
                          max={ fieldDef.max }
                          step={ fieldDef.step }
                          displayValues={ false }
                          displayThumbValue
                          debounce={ 100 }
                          { ...field }
                          onChange={ ({ value }) => {
                            field.onChange(value);
                          } }
                        />
                      ) }
                      control={ control }
                    />
                  </Field>
                );
              }
              if (fieldDef.input === 'color') {
                return (
                  <Field key={ name }>
                    <Controller
                      name={ fieldDef.key }
                      defaultValue={ fieldDef.default }
                      render={ ({ field }) => (
                        <SliderPicker
                          label={ fieldDef.label }
                          defaultValue={ fieldDef.default }
                          color={ field.value }
                          { ...field }
                          onChange={ (value) => {
                            field.onChange(value.hex);
                          } }
                        />
                      ) }
                      control={ control }
                    />
                  </Field>
                );
              }
              return null;
            })
          }
        </Fields>
      </div>
      <div
        role="tabpanel"
        className={ classes.tabpanel1 }
        hidden={ tab !== 2 }
        id="vertical-tabpanel-2"
        aria-labelledby="vertical-tab-2"
      >
        <Fields>
          <Field>
            <Controller
              name={ Fieldset.Attributes }
              control={ control }
              render={ ({ field }) => (
                <AttributesTable
                  attributeDefinitions={ attributeDefinitions }
                  { ...field }
                />
              ) }
            />
          </Field>
        </Fields>
      </div>
      <div className={ classes.modelWrapper }>
        <MaterialModel
          value={ values }
          toneMapping={ company?.toneMapping }
          toneMappingExposure={ company?.toneMappingExposure }
          environment={ environment }
        />
        <Fields>
          <Field>
            <Controller
              name={ Fieldset.ExternalId }
              control={ control }
              render={ ({ field }) => (
                <TextField
                  { ...field }
                  helperText={ fieldsErrors[Fieldset.ExternalId]?.message }
                  label="ID (SKU)"
                  error={ !!fieldsErrors[Fieldset.ExternalId] }
                />
              ) }
            />
          </Field>
          <TranslatedFieldsGroup
            onLangChange={ setSelectedLanguage }
            selectedLanguage={ selectedLanguage }
          >
            <Field>
              <Controller
                name={ useLang
                  ? `${Fieldset.TranslatedFields}.${selectedLanguage.key}.${Fieldset.Name}`
                  : Fieldset.Name }
                key={ useLang
                  ? `${Fieldset.TranslatedFields}.${selectedLanguage.key}.${Fieldset.Name}`
                  : Fieldset.Name }
                control={ control }
                rules={ {
                  required: 'Required',
                } }
                render={ ({ field }) => (
                  <TextField
                    { ...field }
                    helperText={ useLang
                      ? fieldsErrors[`${Fieldset.TranslatedFields}.${selectedLanguage.key}.${Fieldset.Name}`]?.message
                      : fieldsErrors[Fieldset.Name]?.message }
                    label="Name"
                    required
                    error={ useLang
                      ? !!fieldsErrors[`${Fieldset.TranslatedFields}.${selectedLanguage.key}.${Fieldset.Name}`]
                      : !!fieldsErrors[Fieldset.Name] }
                  />
                ) }
              />
            </Field>
            <Field>
              <Controller
                name={ useLang
                  ? `${Fieldset.TranslatedFields}.${selectedLanguage.key}.${Fieldset.Description}`
                  : Fieldset.Description }
                key={ useLang
                  ? `${Fieldset.TranslatedFields}.${selectedLanguage.key}.${Fieldset.Description}`
                  : Fieldset.Description }
                control={ control }
                render={ ({ field }) => (
                  <TextField
                    { ...field }
                    multiline
                    className={ classes.description }
                    label="Description (text/html)"
                    InputLabelProps={ InputLabelProps }
                  />
                ) }
              />
            </Field>
          </TranslatedFieldsGroup>
        </Fields>
      </div>
    </Box>
  );
};

export default FormFieldset;
