import * as THREE from 'three';
import { useThree } from '@react-three/fiber';
import { useCallback, useEffect } from 'react';
import { useUniversalLoader, useMaterials } from '@zolak/zolak-viewer';

const ZOOM_RATIO = 1.28;

const getZoom = (size) => {
  const max = Math.max(size.x, size.z);
  const min = Math.min(size.x, size.z);
  const ratio = size.z * (max / min) * ZOOM_RATIO;
  return size.z + ratio;
};

const useLoadObject = (value, materials = [], onLoad = () => {}, onMaterialsLoad, controls) => {
  const { camera, gl, scene } = useThree();
  const object = useUniversalLoader(value);

  const handleMaterialsLoad = useCallback(() => {
    onMaterialsLoad();
  }, [onMaterialsLoad]);

  useMaterials(object, value.scale, materials, gl, (value.data || value.blob), handleMaterialsLoad);

  const handleLoad = useCallback((size) => {
    gl.render(scene, camera);
    const thumbnail = gl.domElement.toDataURL();
    onLoad({
      object,
      thumbnail,
      size: {
        width: size.x,
        height: size.y,
        length: size.z,
      },
    });
  }, [gl, scene, camera, object, onLoad]);

  useEffect(() => {
    if (object && camera && controls) {
      const box = new THREE.Box3();
      box.setFromObject(object);

      const size = box.getSize(new THREE.Vector3());

      camera.position.set(0, 0.7, getZoom(size));
      controls.update();

      handleLoad(size);
    }

    return () => {
      object.position.x = 0;
      object.position.y = 0;
      object.position.z = 0;
    };
  }, [object, camera, controls]);

  return object;
};

export default useLoadObject;
