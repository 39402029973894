import { FormDialog } from '@shared/views/form-dialog';
import EditModelForm from './Form';
import FormFieldset from '../FormFieldset';
import { useDispatch, useSelector } from 'react-redux';
import { resetModelApiState } from '@store/models/actions';
import { useState } from 'react';


const EditFormDialog = ({
  open,
  data,
  onClose,
}) => {
  const dispatch = useDispatch();

  const apiState = useSelector((state) => state.models.state);

  const [isFormReady, setIsFormReady] = useState(false);

  const handleSubmit = () => {
    dispatch(resetModelApiState());
    onClose();
    setIsFormReady(false);
  };

  const handleCancel = () => {
    dispatch(resetModelApiState());
    onClose();
    setIsFormReady(false);
  };

  const handleFormReady = () => {
    setIsFormReady(true);
  };

  return (
    <FormDialog
      open={ open }
      Form={ EditModelForm }
      FormProps={ {
        open,
        data,
        onSubmit: handleSubmit,
      } }
      title="Edit Model"
      content={ <FormFieldset renewable handleReady={ handleFormReady } /> }
      onCancel={ handleCancel }
      onClose={ onClose }
      isDrawer
      isSubmitDisabled={ !isFormReady || apiState === 'processing' }
    />
  );
};

export default EditFormDialog;
